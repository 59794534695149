<template>
  <div>
    <van-card
      v-for="(item, index) in scenceList"
      :key="index"
      :title="item.siteName"
      :thumb="item.sitePicture"
      @click="toInfo(item)"
    >
      <template #desc>
        <div class="scence-desc">{{ item.siteIntroduction }}</div>
      </template>
    </van-card>
  </div>
</template>
<script>
import { sessionSet } from "@/utils/local";
export default {
  props: ["scenceList"],
  methods: {
    toInfo(item) {
      // sessionSet("siteCourse",item.siteCourse)
      this.$store.commit("updateSiteCourse", item.siteCourse);
      this.$router.push({
        path: "/mobile/scenceInfo",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-card__thumb {
  width: 150px;
	height:90px;
}
/deep/.van-card__title {
  line-height: 1.5em;
  max-height: none;
}
.scence-desc {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; //这个表示要显示几行
  -webkit-box-orient: vertical;
  line-height: 1.5em;
  color: grey;
}
</style>
