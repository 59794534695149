<template>
	<div class="scence">
		<Swipe :swipeImgs="swipeImgs" class="swipe" />
		<van-divider
			:style="{
				color: '#1989fa',
				borderColor: '#1989fa',
				padding: '0 16px',
				margin: '5% 0',
			}"
		>
			场景概览
		</van-divider>
		<div>
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="getSceneList"
				:immediate-check="false"
				offset="1"
			>
				<ScenceList :scenceList="scenceList" />
			</van-list>
		</div>
	</div>
</template>

<script>
import Swipe from './components/swipe'
import ScenceList from './components/scenceList'
import { sceneSlide_api, getSceneList_api } from '@/api/scene'
export default {
	data() {
		return {
			loading: false,
			finished: false,
			swipeImgs: [], //轮播图片
			scenceList: [], //场景列表
			currentPage: 1,
		}
	},
	components: { Swipe, ScenceList },
	methods: {
		//获取轮播图片
		async getImg() {
			let { msg, code, data } = await sceneSlide_api({
				tenantId: this.$tenantId,
			})
			if (code == 200) {
				this.swipeImgs = data
			}
		},
		//获取场景列表
		async getSceneList() {
			this.loading = true
			let { data, code, msg } = await getSceneList_api({
				tenantId: this.$tenantId,
				size: 5,
				sort: 'site_heat',
				sortType: 'desc',
				current: this.currentPage,
			})

			if (code == 200) {
				console.log(data)
				if (data.records.length > 0 && this.currentPage <= data.pages) {
					this.scenceList = this.scenceList.concat(data.records)
					this.currentPage++
					this.loading = false
				} else {
					this.loading = false
					this.finished = true
				}
			}
		},
	},
	mounted() {
		this.getImg()
		this.getSceneList()
	},
}
</script>

<style lang="less" scoped>
.scence {
	.swipe {
		width: 100vw;
		height: 25vh;
		overflow: hidden;
	}
}
</style>
