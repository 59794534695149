<template>
	<div>
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="(item, index) in swipeImgs" :key="index">
				<img v-lazy="item" />
			</van-swipe-item>
		</van-swipe>
	</div>
</template>
<script>
export default {
	props: ['swipeImgs'],
}
</script>
<style lang="less" scoped>
.my-swipe {
	.van-swipe-item {
		img {
			display: inline-block;
			width: 100%;
			height: 25vh;
		}
	}
}
</style>
